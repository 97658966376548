import mixpanel from 'mixpanel-browser';

mixpanel.init('40b654257026f8fc3a116f6ba15e6400', {
  // debug: true,
  persistence: 'localStorage'
});

// Wrapper around mixpanel.track
const track = (name, props) => {
  mixpanel.track(name, props);
};

// Wrapper around mixpanel.identify
const identify = (id) => {
  mixpanel.identify(id);
};

// Wrapper around mixpanel.people.set
const setPeople = (props) => {
  mixpanel.people.set(props);
};

export { track, identify, setPeople };
