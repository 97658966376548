import React from 'react';
import { Avatar } from '@material-ui/core';

function Logo(props) {
  return (
    <Avatar  src='/static/Logo.png' {...props}>
    {/* <img
      alt="Logo"
      src="/static/Logo.png"
      {...props}
    /> */}
    </Avatar>
  );
}

export default Logo;
