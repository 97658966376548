import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
// import analytics from 'src/utils/analytics';

function AuthGuard({ children }) {
  const account = useSelector((state) => state.account);
  // const history = useHistory();

  if (!account.user) {
    return <Redirect to="/stealth/login" />;
  }
  else {
    // analytics.setUser(account.user.email);

    // // Log initial page load
    // analytics.pageview({ page_path: window.location.pathname });

    // // Log subsequent page views
    // history.listen((location) => {
    //   analytics.pageview({ page_path: location.pathname });
    // });
    if (window.gtag) {
      if(account.user.email !== "company@email.com")
      window.gtag('event', 'User_Activity', {
        method: 'text',
        user_email: account.user.email.replace("@", "-"),
        page_path: window.location.pathname,
        timestamp: new Date().toISOString()
      });
    }
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
