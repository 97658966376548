// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const APP_VERSION = '1.0.0';
export const API_BASE_URL = 'https://1000containers.com/api';
// export const API_BASE_URL = 'http://74.208.61.158:8000/api';
// export const API_BASE_URL = 'http://localhost:8000/api';

export const BUILDER_KEY = 'eec886b057e3473cabe84bf9ba8a9fa3';

export const ENABLE_REDUX_LOGGER = false;

export default {};
